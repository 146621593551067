// Styles
//import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
// 使用するアイコンのみインポートする
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { mdiHome, mdiWhiteBalanceSunny, mdiOpenInNew, mdiChevronRight } from '@mdi/js'

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      home: mdiHome,
      sunny: mdiWhiteBalanceSunny,
      openNew: mdiOpenInNew,
      right: mdiChevronRight,
    },
    sets: {
      mdi,
    },
  },
})
