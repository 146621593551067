<template>
  <div class="home">
    <v-app>
      <v-app-bar color="blue-darken-1" prominent>
        <v-app-bar-nav-icon @click="drawer ? drawer = false : drawer = true"></v-app-bar-nav-icon>
        <v-toolbar-title>
          <a href="https://weather.monsieurlangue.com/" style="text-decoration:none; color:white">
            平均気温と降水量グラフ
          </a>
        </v-toolbar-title>
      </v-app-bar>
      <!-- サイドメニュー -->
      <v-navigation-drawer v-model="drawer" absolute temporary style="position:fixed;">
        <v-list nav dense>
          <v-list-item
            class="font-weight-bold"
            href="/"
          >
          <v-icon icon="$home" />
          平均気温と降水量グラフ
          </v-list-item>

          <!-- フランスの主要都市の天気 -->
          <v-list-group value="France">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-icon icon="$sunny" />
                フランスの天気
              </v-list-item>
            </template>
            <v-list-item class="font-weight-bold" href="/france/" v-bind="props">
              フランス各地の天気
            </v-list-item>
            <v-list-item
              v-for="city in franceBigCities"
              :key="city"
              :value="city.tag"
              :href="'/france/' + city.tag + '/'"
              v-bind="props"
            >
              {{ city.name }}の天気
            </v-list-item>
          </v-list-group>

          <!-- 日本の主要都市の天気 -->
          <v-list-group value="Japan">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-icon icon="$sunny" />
                日本の天気
              </v-list-item>
            </template>
            <v-list-item class="font-weight-bold" href="/japan/" v-bind="props">
              日本各地の天気
            </v-list-item>
            <v-list-item
              v-for="city in japanBigCities"
              :key="city"
              :value="city.tag"
              :href="'/japan/' + city.tag + '/'"
              v-bind="props"
            >
            {{ city.name }}の天気
            </v-list-item>
          </v-list-group>

          <!-- アメリカの主要都市の天気 -->
          <v-list-group value="Usa">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-icon icon="$sunny" />
                アメリカの天気
              </v-list-item>
            </template>
            <v-list-item class="font-weight-bold" href="/usa/" v-bind="props">
              アメリカ各地の天気
            </v-list-item>
            <v-list-item
              v-for="city in usaBigCities"
              :key="city"
              :value="city.tag"
              :href="'/usa/' + city.tag + '/'"
              v-bind="props"
            >
            {{ city.name }}の天気
            </v-list-item>
          </v-list-group>

        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-row no-gutters justify="center">
          <!-- Google Adsense TOP -->
          <div id="adsTop" style="width:1000px; max-width:100%; text-align:center;" v-html="adsContentTop"></div>
        </v-row>

        <v-row no-gutters justify="center">
          <v-col cols="12" md="9">
            <router-view />
          </v-col>

          <v-col cols="12" md="2">

            <!-- Google Adsense SIDE -->
            <div id="adsSide" style="width:300px; max-width:100%;" v-html="adsContentSide"></div>

            <v-list>

              <!-- フランスの主要都市の天気 -->
              <v-list-item
                class="font-weight-bold"
                href="/france/"
              >
              <v-icon icon="$sunny" />
              フランスの天気
              </v-list-item>
              <v-list-item
                v-for="city in franceBigCities"
                :key="city"
                :value="city.tag"
                :href="'/france/' + city.tag + '/'"
              >
              {{ city.name }}の天気
              </v-list-item>
              <v-list-item
                href="/france/"
              >
              その他の都市の天気
              </v-list-item>

              <!-- 日本の主要都市の天気 -->
              <v-list-item
                class="font-weight-bold"
                href="/japan/"
              >
              <v-icon icon="$sunny" />
              日本の天気
              </v-list-item>
              <v-list-item
                v-for="city in japanBigCities"
                :key="city"
                :value="city.tag"
                :href="'/japan/' + city.tag + '/'"
              >
              {{ city.name }}の天気
              </v-list-item>
              <v-list-item
                href="/japan/"
              >
              その他の都市の天気
              </v-list-item>

              <!-- アメリカの主要都市の天気 -->
              <v-list-item
                class="font-weight-bold"
                href="/usa/"
              >
              <v-icon icon="$sunny" />
              アメリカの天気
              </v-list-item>
              <v-list-item
                v-for="city in usaBigCities"
                :key="city"
                :value="city.tag"
                :href="'/usa/' + city.tag + '/'"
              >
              {{ city.name }}の天気
              </v-list-item>
              <v-list-item
                href="/usa/"
              >
              その他の都市の天気
              </v-list-item>

            </v-list>
          </v-col>
        </v-row>

        <v-row no-gutters justify="center">
          <!-- Google Adsense BOTTOM -->
          <div id="adsBottom" style="width:1000px; max-width:100%;" v-html="adsContentBottom"></div>
        </v-row>
      </v-main>

      <!-- フッター -->
      <v-footer class="bg-blue-darken-1">
        <v-row justify="center" no-gutters>
          <v-btn
            v-for="link in links"
            :key="link"
            color="white"
            variant="text"
            rounded="xl"
            :href="link.url"
            target="_blank"
          >
          {{ link.urlname }}
          <v-icon icon="$openNew" class="ml-1" />
          </v-btn>

          <v-col class="text-center my-1" cols="12">
            <v-btn
              v-for="link in siteInfo"
              :key="link"
              color="white"
              variant="text"
              rounded="xl"
              :href="link.url"
            >
            {{ link.urlname }}
            </v-btn>
          </v-col>

          <v-col class="text-center my-2" cols="12">
            Copyright © {{ new Date().getFullYear() }} <a href="https://monsieurlangue.com/" class="text-decoration-none" style="color:white">Monsieur Langue</a>
          </v-col>
        </v-row>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
export default {
  name: "app",
  data: () => ({
    drawer: false,
    adsContentTop: '',
    adsContentBottom: '',
    adsContentSide: '',

    franceBigCities: [
      { name: "パリ", tag: "paris" },
      { name: "モン・サン＝ミシェル", tag: "mont-saint-michel" },
      { name: "ヴェルサイユ", tag: "versailles" },
      { name: "ボルドー", tag: "bordeaux" },
      { name: "リヨン", tag: "lyon" },
      { name: "マルセイユ", tag: "marseille" },
      { name: "ニース", tag: "nice" },
      { name: "トゥールーズ", tag: "toulouse" },
      { name: "ストラスブール", tag: "strasbourg" },
      { name: "ナント", tag: "nantes" }
    ],

    japanBigCities: [
      { name: "北海道札幌市", tag: "sapporo" },
      { name: "東京都東京", tag: "tokyo" },
      { name: "神奈川県横浜市", tag: "yokohama" },
      { name: "愛知県名古屋市", tag: "nagoya" },
      { name: "京都府京都市", tag: "kyoto" },
      { name: "大阪府大阪市", tag: "osaka" },
      { name: "兵庫県神戸市", tag: "kobe" },
      { name: "奈良県奈良市", tag: "nara" },
      { name: "広島県広島市", tag: "hiroshima" },
      { name: "沖縄県那覇市", tag: "naha" }
    ],

    usaBigCities: [
      { name: "ニューヨーク", tag: "new-york-new-york-city" },
      { name: "ホノルル", tag: "hawaii-honolulu" },
      { name: "ロサンゼルス", tag: "california-los-angeles" },
      { name: "ラスベガス", tag: "nevada-las-vegas" },
      { name: "シカゴ", tag: "illinois-chicago" },
      { name: "シアトル", tag: "washington-seattle" },
      { name: "ボストン", tag: "massachusetts-boston" },
      { name: "ポートランド", tag: "oregon-portland" },
      { name: "ヒューストン", tag: "texas-houston" },
      { name: "デトロイト", tag: "michigan-detroit" }
    ],

    links: [
      {
        urlname: "浮世絵で見る七十二候",
        url: "https://72seasons.maikojapan.com/"
      },
      {
        urlname: "にほんごの書き順",
        url: "https://nihongo.maikojapan.com/jp/"
      },
      {
        urlname: "フランス語と英語を同時に学ぶ",
        url: "https://monsieurlangue.com/"
      },
      {
        urlname: "フランス語クイズ",
        url: "https://quiz.monsieurlangue.com/"
      }
    ],

    siteInfo: [
      {
        urlname: "利用規約",
        url: "https://weather.monsieurlangue.com/privacy_policy/"
      },
      {
        urlname: "お問い合わせ",
        url: "https://monsieurlangue.com/contactmosieurlangue/"
      }
    ],
  }),

  mounted() {
    if (document.getElementById('divadsenseTop')) {
      this.adsContentTop = document.getElementById('divadsenseTop').innerHTML
    }
    if (document.getElementById('divadsenseBottom')) {
      this.adsContentBottom = document.getElementById('divadsenseBottom').innerHTML
    }
    if (document.getElementById('divadsenseSide')) {
      this.adsContentSide = document.getElementById('divadsenseSide').innerHTML
    }
  }
};
</script>
